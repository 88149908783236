import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enJSON from "@shared/locale/en.json";
import ruJSON from "@shared/locale/ru.json";

i18n.use(initReactI18next).init({
    resources: {
        en: { ...enJSON },
        ru: { ...ruJSON },
    },
    lng: "ru",
});