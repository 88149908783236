


export enum OrderStatus {
    CREATED = 'CREATED',
    NEW = 'NEW',
    IN_PROGRESS = "IN_PROGRESS",
    READY = "READY",
    REJECTED = "REJECTED",
    CANCELLED = "CANCELLED",
    FINISHED = "accepted",
    UNFINISHED = "un_accepted",
}
