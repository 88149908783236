import {SagaIterator} from "redux-saga";
import {call, put, takeLatest} from "redux-saga/effects";
import {
    authenticationFailure,
    authenticationRequest,
    authenticationSuccess,
    logoutFailure,
    logoutRequest,
    logoutSuccess
} from "../slice/authenticationSlice";
import {Authentication} from "../../api/Authentication";

function* authenticationEffect ({payload}: any): SagaIterator {
    try {
        const response = yield call(Authentication.authenticationApi, {
            login: payload?.login,
            password: payload?.password
        });
        yield put(authenticationSuccess(response));
        if(Boolean(payload?.onSuccess)) payload?.onSuccess();
    } catch (error) {
        yield put(authenticationFailure(error));
        if(Boolean(payload?.onError)) payload?.onError();
    }
}

function * logoutEffect (): SagaIterator {
    try {
        yield call(Authentication.logoutApi);
        yield put(logoutSuccess());
    } catch (error) {
        yield put(logoutFailure());
    }
}
export function * watchAuthenticationEffects (): SagaIterator {
    yield takeLatest(authenticationRequest, authenticationEffect);
    yield takeLatest(logoutRequest, logoutEffect);
}
