import {createSlice} from "@reduxjs/toolkit";
import {Status} from "@shared/enum/status";

interface AuthenticationState {
    newOrderList: {
        data: any[],
        next: number | null,
        currentPage: number | null,
        loadMore: boolean,
        status: Status,
        error: any
    },
    inProgressOrderList: {
        data: any[],
        next: number | null,
        currentPage: number | null,
        loadMore: boolean,
        status: Status,
        error: any
    },
    readyOrderList: {
        data: any[],
        next: number | null,
        currentPage: number | null,
        loadMore: boolean,
        status: Status,
        error: any
    },
    uncollectedOrderList: {
        data: any[],
        next: number | null,
        currentPage: number | null,
        loadMore: boolean,
        status: Status,
        error: any
    },
    reasonList: {
        data: any[],
        status: Status,
        error: any
    }
}

const initialState: AuthenticationState = {
    newOrderList: {
        data: [],
        next: 1,
        currentPage: 0,
        loadMore: true,
        status: Status.IDLE,
        error: null
    },
    inProgressOrderList: {
        data: [],
        next: 1,
        currentPage: 0,
        loadMore: true,
        status: Status.IDLE,
        error: null
    },
    readyOrderList: {
        data: [],
        next: 1,
        currentPage: 0,
        loadMore: true,
        status: Status.IDLE,
        error: null
    },
    uncollectedOrderList: {
        data: [],
        next: 1,
        currentPage: 0,
        loadMore: true,
        status: Status.IDLE,
        error: null
    },
    reasonList: {
        data: [],
        status: Status.IDLE,
        error: null
    }
}

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        getNewOrderListRequest: (state, action) => {
            state.newOrderList.status = Status.PENDING;
            state.newOrderList.currentPage = action.payload.page;
            if(action.payload.page === 1) {
                state.newOrderList.data = [];
            }
        },
        getNewOrderListSuccess: (state, action) => {
            const list = JSON.parse(JSON.stringify(state.newOrderList.data))
            state.newOrderList.status = Status.SUCCESS;
            state.newOrderList.next = action.payload.next ? action.payload.next : null;
            state.newOrderList.loadMore = !!action.payload.next;
            state.newOrderList.data = [...list, ...action.payload.results];
        },
        getNewOrderListFailure: (state) => {
            state.newOrderList.status = Status.FAIL;
            state.newOrderList.loadMore = false;
        },
        getInProgressOrderListRequest: (state, action) => {
            state.inProgressOrderList.status = Status.PENDING;
            state.inProgressOrderList.currentPage = action.payload.page;
            if(action.payload.page === 1) {
                state.inProgressOrderList.data = [];
            }
        },
        getInProgressOrderListSuccess: (state, action) => {
            const list = JSON.parse(JSON.stringify(state.inProgressOrderList.data))
            state.inProgressOrderList.status = Status.SUCCESS;
            state.inProgressOrderList.next = action.payload.next ? action.payload.next : null;
            state.inProgressOrderList.loadMore = !!action.payload.next;
            state.inProgressOrderList.data = [...list, ...action.payload.results];
        },
        getInProgressOrderListFailure: (state) => {
            state.inProgressOrderList.loadMore = false;
            state.inProgressOrderList.status = Status.FAIL;
        },
        getReadyOrderListRequest: (state, action) => {
            state.readyOrderList.status = Status.PENDING;
            state.readyOrderList.currentPage = action.payload.page;
            if(action.payload.page === 1) {
                state.readyOrderList.data = [];
            }
        },
        getReadyOrderListSuccess: (state, action) => {
            const list = JSON.parse(JSON.stringify(state.readyOrderList.data))
            state.readyOrderList.status = Status.SUCCESS;
            state.readyOrderList.next = action.payload.next ? action.payload.next : null;
            state.readyOrderList.loadMore = !!action.payload.next;
            state.readyOrderList.data = [...list, ...action.payload.results];
        },
        getReadyOrderListFailure: (state) => {
            state.readyOrderList.status = Status.FAIL;
            state.readyOrderList.loadMore = false;
        },



        getUncollectedOrderListRequest: (state, action) => {
            state.uncollectedOrderList.status = Status.PENDING;
            state.uncollectedOrderList.currentPage = action.payload.page;
            if(action.payload.page === 1) {
                state.uncollectedOrderList.data = [];
            }
        },
        getUncollectedOrderListSuccess: (state, action) => {
            const list = JSON.parse(JSON.stringify(state.readyOrderList.data))
            state.uncollectedOrderList.status = Status.SUCCESS;
            state.uncollectedOrderList.next = action.payload.next ? action.payload.next : null;
            state.uncollectedOrderList.loadMore = !!action.payload.next;
            state.uncollectedOrderList.data = [...list, ...action.payload.results];
        },
        getUncollectedOrderListFailure: (state) => {
            state.uncollectedOrderList.status = Status.FAIL;
            state.uncollectedOrderList.loadMore = false;
        },
        updateOrderByIdFromNewToInProgressRequest: (state, action) => {

        },
        updateOrderByIdFromNewToInProgressSuccess: (state, action) => {
            const list = JSON.parse(JSON.stringify(state.newOrderList.data))
            if(list.filter((item: any) => item.id === action.payload.id)) {
                const index = list.findIndex((item: any) => item.id === action.payload.id)
                state.newOrderList.data.splice(index, 1)
            }
            state.inProgressOrderList.data.unshift(action.payload)
        },
        updateOrderByIdFromNewToInProgressFailure: (state) => {

        },
        updateOrderByIdFromInProgressToReadyRequest: (state, action) => {

        },
        updateOrderByIdFromInProgressToReadySuccess: (state, action) => {
            const list = JSON.parse(JSON.stringify(state.inProgressOrderList.data))
            if(list.filter((item: any) => item.id === action.payload.id)) {
                const index = list.findIndex((item: any) => item.id === action.payload.id)
                state.inProgressOrderList.data.splice(index, 1)
            }
            state.readyOrderList.data.unshift(action.payload)
        },
        updateOrderByIdFromInProgressToReadyFailure: (state) => {

        },
        updateOrderByIdFromReadyToDoneRequest: (state, action) => {

        },
        updateOrderByIdFromReadyToDoneSuccess: (state, action) => {
            const list = JSON.parse(JSON.stringify(state.readyOrderList.data))
            if(list.filter((item: any) => item.id === action.payload.id)) {
                const index = list.findIndex((item: any) => item.id === action.payload.id)
                state.readyOrderList.data.splice(index, 1)
            }
        },
        updateOrderByIdFromReadyToDoneFailure: (state) => {

        },
        updateOrderByIdFromReadyToUndoneRequest: (state, action) => {

        },
        updateOrderByIdFromReadyToUndoneSuccess: (state, action) => {
            const list = JSON.parse(JSON.stringify(state.readyOrderList.data))
            if(list.filter((item: any) => item.id === action.payload.id)) {
                const index = list.findIndex((item: any) => item.id === action.payload.id)
                state.readyOrderList.data.splice(index, 1)
            }
        },
        updateOrderByIdFromReadyToUndoneFailure: (state) => {

        },
        addOrderList: (state, action) => {
            const orderList = JSON.parse(JSON.stringify(state.newOrderList.data))
            if(!orderList.includes((item: any) => item.id === action.payload.id)) {
                state.newOrderList.data.unshift(action.payload)
            }
        },
        removeItemFromBlockList: (state, action) => {
            const blockList = JSON.parse(JSON.stringify(state.uncollectedOrderList.data))
            if(blockList.filter((item: any) => item.id === action.payload.id)?.length > 0) {
                const indexItem = blockList.findIndex((item: any) => item.id === action.payload.id)
                if(indexItem > -1) {
                    state.uncollectedOrderList.data.splice(indexItem, 1)
                }
            }
        },
        removeItemFromOrderList: (state, action) => {
            const orderList = JSON.parse(JSON.stringify(state.newOrderList.data))
            if(orderList.filter((item: any) => item.id === action.payload.id)?.length > 0) {
                const indexItem = orderList.findIndex((item: any) => item.id === action.payload.id)
                if(indexItem > -1) {
                    state.newOrderList.data.splice(indexItem, 1)
                }
            }
        },
        getReasonListRequest: (state) => {
            state.reasonList.status = Status.PENDING
        },
        getReasonListSuccess: (state, action) => {
            state.reasonList.status = Status.SUCCESS;
            state.reasonList.data = action.payload.results;
        },
        getReasonListFailure: (state) => {
            state.reasonList.status = Status.FAIL
        },
        updateStatusToRejectedRequest: (state, action) => {

        },
        updateStatusToRejectedSuccess: (state, action) => {
            const id = action.payload.id
            const status = action.payload.status

            if(status === "new") {
                const orderList = JSON.parse(JSON.stringify(state.newOrderList.data))
                if(orderList.filter((item: any) => item.id === id)?.length > 0) {
                    const indexItem = orderList.findIndex((item: any) => item.id === id)
                    if(indexItem > -1) {
                        state.newOrderList.data.splice(indexItem, 1)
                    }
                }
            }
            if(status === "in_progress") {
                const orderList = JSON.parse(JSON.stringify(state.inProgressOrderList.data))
                if(orderList.filter((item: any) => item.id === id)?.length > 0) {
                    const indexItem = orderList.findIndex((item: any) => item.id === id)
                    if(indexItem > -1) {
                        state.inProgressOrderList.data.splice(indexItem, 1)
                    }
                }
            }

        },
        updateStatusToRejectedFailure: (state) => {

        },
        sendReasonOfCancelRequest: (state, action) => {

        },
        sendReasonOfCancelSuccess: (state, payload) => {

        },
        sendReasonOfCancelFailure: (state) => {

        },
    }
})


export const {
    getNewOrderListRequest,
    getNewOrderListSuccess,
    getNewOrderListFailure,
    getInProgressOrderListRequest,
    getInProgressOrderListSuccess,
    getInProgressOrderListFailure,
    getReadyOrderListRequest,
    getReadyOrderListSuccess,
    getReadyOrderListFailure,
    getUncollectedOrderListRequest,
    getUncollectedOrderListSuccess,
    getUncollectedOrderListFailure,
    addOrderList,
    removeItemFromOrderList,
    updateOrderByIdFromNewToInProgressRequest,
    updateOrderByIdFromNewToInProgressSuccess,
    updateOrderByIdFromNewToInProgressFailure,
    updateOrderByIdFromInProgressToReadyRequest,
    updateOrderByIdFromInProgressToReadySuccess,
    updateOrderByIdFromInProgressToReadyFailure,
    updateOrderByIdFromReadyToDoneRequest,
    updateOrderByIdFromReadyToDoneSuccess,
    updateOrderByIdFromReadyToDoneFailure,
    updateOrderByIdFromReadyToUndoneRequest,
    updateOrderByIdFromReadyToUndoneSuccess,
    updateOrderByIdFromReadyToUndoneFailure,
    getReasonListRequest,
    getReasonListSuccess,
    getReasonListFailure,
    sendReasonOfCancelRequest,
    sendReasonOfCancelSuccess,
    sendReasonOfCancelFailure,
    updateStatusToRejectedRequest,
    updateStatusToRejectedSuccess,
    updateStatusToRejectedFailure,
    removeItemFromBlockList,
} = orderSlice.actions;

export const { reducer: orderReducer } = orderSlice;
