import {SagaIterator} from "redux-saga";
import {call, put, takeLatest} from "redux-saga/effects";
import {
    changeWorkDayFailure,
    changeWorkDayRequest, changeWorkDaySuccess, getUserInfoFailure, getUserInfoRequest, getUserInfoSuccess,
    getWorkDaysFailure,
    getWorkDaysRequest,
    getWorkDaysSuccess
} from "@entities/WorkDays/model/slice/workDaysSlice";
import {WorkDays} from "@entities/WorkDays/api/WorkDays";

function* getWorkDaysEffect (): SagaIterator {
    try {
        const response = yield call(WorkDays.getWorkDaysList);
        yield put(getWorkDaysSuccess(response));
    } catch (error) {
        yield put(getWorkDaysFailure());
    }
}

function* changeWorkDayEffect ({ payload: { id, dayOff, onSuccess, onFailure }}: any): SagaIterator {
    try {
        const response = yield call(WorkDays.changeWorkDayApi, id, dayOff);
        yield put(changeWorkDaySuccess({
            id,
            response
        }));
        onSuccess();
    } catch (error) {
        yield put(changeWorkDayFailure());
        onFailure();
    }
}

function* getUserInfoEffect (): SagaIterator {
    try {
        const response = yield call(WorkDays.getUserInfoApi);
        yield put(getUserInfoSuccess(response));
    } catch (error) {
        yield put(getUserInfoFailure());
    }
}

export function* watchWorkDaysEffects (): SagaIterator {
    yield takeLatest(getWorkDaysRequest, getWorkDaysEffect);
    yield takeLatest(changeWorkDayRequest, changeWorkDayEffect);
    yield takeLatest(getUserInfoRequest, getUserInfoEffect);
}
