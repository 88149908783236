import {FC} from "react";

import cls from "./Toggle.module.scss"

export const Toggle: FC<any> = ({
    checked,
    onClick
                       }) => {
    return (
        <label
            className={cls.container}
        >
            <input
                onClick={onClick}
                className={cls.checkbox}
                type="checkbox"
                checked={checked}
            />
            <span className={cls.span} />
        </label>
    )
}