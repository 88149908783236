// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationPage_title__WqYVQ {
  font-size: 24px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotificationPage/NotificationPage.module.scss"],"names":[],"mappings":"AAGA;EACI,eAAA;EACA,iBAAA;AAFJ","sourcesContent":["\n\n\n.title {\n    font-size: 24px;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `NotificationPage_title__WqYVQ`
};
export default ___CSS_LOADER_EXPORT___;
