import {useDispatch, useSelector} from "react-redux";

import InfiniteScroll from "react-infinite-scroller";


import {getInProgressOrderListRequest} from "@entities/Order/model/slice/orderSlice";
import {
    selectInProgressOrderListCurrentPage,
    selectInProgressOrderListData,
    selectInProgressOrderListLoadMore,
    selectInProgressOrderListNext,
    selectInProgressOrderListStatus,
} from "@entities/Order/model/selectors/orderSelectors";

import {OrderCard} from "@shared/ui/OrderCard/OrderCard";
import {Status} from "@shared/enum/status";
import {Loader} from "@shared/ui/Loader/Loader";
import {classNames} from "@shared/lib";

import cls from "./InProgressOrderTab.module.scss";


export const InProgressOrderTab = () => {
    const dispatch = useDispatch();
    const orderList = useSelector(selectInProgressOrderListData);
    const status = useSelector(selectInProgressOrderListStatus);
    const nextPage = useSelector(selectInProgressOrderListNext);
    const currentPage = useSelector(selectInProgressOrderListCurrentPage);
    const loadMore = useSelector(selectInProgressOrderListLoadMore);

    return (
        <div>
            <div className={cls.wrap}>
                {orderList?.length === 0 && status !== Status.PENDING && (
                    <div className={cls.inProgressOrderTabIsEmpty}>
                        На данный момент нет заказов в процессе готовки
                    </div>
                )}

                {/*{orderList?.length === 0 && status === Status.PENDING && (*/}
                {/*    <div className={cls.inProgressOrderTabIsEmpty}>*/}
                {/*        <div className={cls.loader} />*/}
                {/*    </div>*/}
                {/*)}*/}
                <InfiniteScroll
                    pageStart={0}
                    loadMore={() => {
                        if(currentPage !== nextPage) {
                            dispatch(getInProgressOrderListRequest({page: nextPage}))
                        }
                    }}
                    hasMore={loadMore}
                    loader={(
                        <div key={0} className={classNames(cls.scrollLoader, {
                            [cls.first]: orderList?.length === 0 && status === Status.PENDING
                        }, [])}>
                            <Loader />
                        </div>
                    )}
                >
                    <div className={cls.orderCards}>
                        {orderList?.map((item: any) => (
                            <OrderCard
                                product={item}
                                key={item?.id}
                                id={item?.id}
                                secretCode={item?.secret_code}
                                phone={item?.user?.phone}
                                prepareTime={item?.prepare_time}
                                price={item?.price}
                                createdAt={item?.created_at}
                                foodOrders={item?.products}
                                status="in_progress"
                            />
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    )
}