import cls from "./HomePage.module.scss"
import {useSelector} from "react-redux";
import {selectUserData} from "@entities/WorkDays/model/selectors/workDaysSelectors";

export const HomePage = () => {
    const user = useSelector(selectUserData);

    return (
        <h1 className={cls.title}>
            Добро пожаловать на {user?.branch?.address}!
        </h1>
    )
}