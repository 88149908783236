// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UncollectedOrderPage_orderCards__tlNJO {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.UncollectedOrderPage_scrollLoader__bXAnJ {
  width: 100%;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.UncollectedOrderPage_first__9IBnV {
  min-height: calc(100vh - 400px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.UncollectedOrderPage_title__\\+Cpw1 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
}

.UncollectedOrderPage_newOrderTabIsEmpty__rjbt\\+ {
  color: #3a3a3a;
  font-size: 20px;
  height: 100%;
  min-height: calc(100vh - 300px);
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/UncollectedOrderPage/UncollectedOrderPage.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AADJ;;AAKA;EACI,WAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;;AAKA;EACI,+BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFJ;;AAKA;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;AAFJ;;AAMA;EACI,cAAA;EACA,eAAA;EACA,YAAA;EACA,+BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAHJ","sourcesContent":["\n\n.orderCards {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n\n\n.scrollLoader {\n    width: 100%;\n    padding: 50px 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.first {\n    min-height: calc(100vh - 400px);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.title {\n    font-size: 32px;\n    font-weight: 700;\n    margin-bottom: 20px;\n}\n\n\n.newOrderTabIsEmpty {\n    color: #3a3a3a;\n    font-size: 20px;\n    height: 100%;\n    min-height: calc(100vh - 300px);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orderCards": `UncollectedOrderPage_orderCards__tlNJO`,
	"scrollLoader": `UncollectedOrderPage_scrollLoader__bXAnJ`,
	"first": `UncollectedOrderPage_first__9IBnV`,
	"title": `UncollectedOrderPage_title__+Cpw1`,
	"newOrderTabIsEmpty": `UncollectedOrderPage_newOrderTabIsEmpty__rjbt+`
};
export default ___CSS_LOADER_EXPORT___;
