import {useDispatch, useSelector} from "react-redux";

import InfiniteScroll from "react-infinite-scroller";

import {
    getReadyOrderListRequest
} from "@entities/Order/model/slice/orderSlice";
import {
    selectReadyOrderListCurrentPage,
    selectReadyOrderListData,
    selectReadyOrderListLoadMore,
    selectReadyOrderListNext,
    selectReadyOrderListStatus
} from "@entities/Order/model/selectors/orderSelectors";

import {OrderCard} from "@shared/ui/OrderCard/OrderCard";
import {Status} from "@shared/enum/status";
import {Loader} from "@shared/ui/Loader/Loader";
import {classNames} from "@shared/lib";

import cls from "./ReadyOrderTab.module.scss";


export const ReadyOrderTab = () => {

    const dispatch = useDispatch();
    const orderList = useSelector(selectReadyOrderListData);
    const status = useSelector(selectReadyOrderListStatus);
    const nextPage = useSelector(selectReadyOrderListNext);
    const currentPage = useSelector(selectReadyOrderListCurrentPage);
    const loadMore = useSelector(selectReadyOrderListLoadMore);

    return (
        <div>
            <div className={cls.wrap}>
                {orderList?.length === 0 && status !== Status.PENDING  && (
                    <div className={cls.readyOrderTabIsEmpty}>
                        На данный момент нет готовых заказов
                    </div>
                )}
                {/*{orderList?.length === 0 && status === Status.PENDING && (*/}
                {/*    <div className={cls.readyOrderTabIsEmpty}>*/}
                {/*        <div className={cls.loader} />*/}
                {/*    </div>*/}
                {/*)}*/}
                <InfiniteScroll
                    pageStart={0}
                    loadMore={() => {
                        if(currentPage !== nextPage) {
                            dispatch(getReadyOrderListRequest({page: nextPage}))
                        }
                    }}
                    hasMore={loadMore}
                    loader={(
                        <div key={0} className={classNames(cls.scrollLoader, {
                            [cls.first]: orderList?.length === 0 && status === Status.PENDING
                        }, [])}>
                            <Loader />
                        </div>
                    )}
                >
                    <div className={cls.orderCards}>
                        {orderList?.map((item: any) => (
                            <OrderCard
                                key={item?.id}
                                id={item?.id}
                                secretCode={item?.secret_code}
                                phone={item?.user?.phone}
                                comment={item?.comment}
                                prepareTime={item?.prepare_time}
                                price={item?.price}
                                createdAt={item?.created_at}
                                foodOrders={item?.products}
                                status="ready"
                            />
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    )
}
