import {$api} from "@api/api";

import {OrderStatus} from "@shared/enum/orderStatus";

import {buildUrlOrderListWithStatus, buildUrlUpdateOrderStatus} from "../consts/routes";


export class Order {
    static async getOrderList(status: OrderStatus, page: any): Promise<any> {
        const response = await $api.get(buildUrlOrderListWithStatus(status), {
            params: {
                page: page,
                page_size: 10
            }
        });
        return response.data;
    }

    static async updateOrderStatusById(id: any, status: any): Promise<any> {
        const response = await $api.put(buildUrlUpdateOrderStatus(id), {status});
        return response.data;
    }

    static async getReasonListApi(): Promise<any> {
        const response = await $api.get('/api/v1/reasons/');
        return response.data;
    }

    static async sendReasonOfCancelApi(id: any, data: any): Promise<any> {
        const response = await $api.post(`/api/v1/mvp/order/${id}/reason/`, data);
        return response.data;
    }



}