import {createSelector} from "reselect";


const selectNotificationListSlice = (state: any) => state.notificationListReducer;

export const selectNotificationList = (state: any) => selectNotificationListSlice(state).data


export const selectNotificationLength = createSelector(selectNotificationList, (data) => {
    return data?.length ? data?.filter((item: any) => !item?.isRead)?.length : 0;
})

