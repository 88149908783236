export const APP_PAGES = {
    ALL: '*',
    MAIN: '/main',
    INSTITUTION: '/main/institution/:id',
    INSTITUTION_DETAIL: '/main/institution/:id/detail',
    CATEGORY: '/category',
    WORK_DAYS: '/dashboard/work-days',
    BASKET: '/basket',
    MORE: '/more',
    OTP_REGISTRATION: '/otp-registration',
    NOTIFICATIONS: '/dashboard/notifications',
    UNCOLLECTED_ORDER: '/dashboard/uncollected-order',
}
