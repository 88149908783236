import {createSlice} from "@reduxjs/toolkit";

import {Status} from "@shared/enum/status";

interface AuthenticationState {
    blockList: {
        data: any
        status: Status
        error: any
    },

}

const initialState: AuthenticationState = {
    blockList: {
        data: null,
        status: Status.IDLE,
        error: null
    },
}

const blockListSlice = createSlice({
    name: 'blockList',
    initialState,
    reducers: {
        blockListByOrderIdRequest: (state, action) => {
            state.blockList.status = Status.PENDING;
            state.blockList.error = null;
        },
        blockListByOrderIdSuccess: (state, action) => {
            state.blockList.status = Status.SUCCESS;
        },
        blockListByOrderIdFailure: (state) => {
            state.blockList.status = Status.FAIL;

        }
    }
})


export const {
    blockListByOrderIdRequest,
    blockListByOrderIdSuccess,
    blockListByOrderIdFailure,
} = blockListSlice.actions;

export const { reducer: blockListReducer } = blockListSlice;
