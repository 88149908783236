// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modalFixed__Mol1- {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Modal/Modal.module.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,aAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,cAAA;AADJ","sourcesContent":["\n\n.modalFixed {\n    position: fixed;\n    height: 100vh;\n    width: 100vw;\n    top: 0;\n    left: 0;\n    z-index: 999;\n    overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalFixed": `Modal_modalFixed__Mol1-`
};
export default ___CSS_LOADER_EXPORT___;
