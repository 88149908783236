import {FC, Fragment, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {selectReasonListData, selectReasonListStatus} from "@entities/Order/model/selectors/orderSelectors";
import {
    sendReasonOfCancelRequest,
    updateStatusToRejectedRequest
} from "@entities/Order/model/slice/orderSlice";

import {useCheckRequestStatus} from "@shared/hooks/useCheckRequestStatus";
import {classNames} from "@shared/lib";
import {Loader} from "@shared/ui/Loader/Loader";
import {CheckIcon} from "@shared/icons/CheckIcon/CheckIcon";

import cls from "./ReasonPage.module.scss";

export const ReasonPage: FC<any> = ({id, secretCode, product, onClose, status}) => {
    const dispatch = useDispatch();

    const list = useSelector(selectReasonListData);
    const reasonListStatus = useSelector(selectReasonListStatus);

    const {
        isPending,
    } = useCheckRequestStatus(reasonListStatus)

    const [checkedId, setCheckedId] = useState(null)
    const [isSelectProduct, setIsSelectProduct] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState<number[]>([]);


    return (
        <div
            onClick={onClose}
            className={cls.shutter}
        >
            <div
                className={cls.modalContainer}
                onClick={(event: any) => {
                    event.stopPropagation();
                    event.preventDefault();
                }}
            >
                {isSelectProduct ? (
                    <Fragment>
                        <h1 className={cls.title}>Выберите продукты, которые отсутствуют:</h1>
                        <div className={cls.reasonContainer}>
                            {product?.map((item: any) => {
                                const isSelected = selectedProducts?.filter((value) => value === item?.product?.id)?.length > 0

                                return (
                                    <div
                                        key={item.id}
                                        onClick={() => {
                                            if(isSelected) {
                                                const index = selectedProducts.indexOf(item?.product?.id)
                                                const newArray = selectedProducts
                                                newArray.splice(index, 1)
                                                setSelectedProducts([...newArray])
                                                return;
                                            }
                                            setSelectedProducts(prevState => [...prevState, item?.product?.id])
                                        }}
                                        className={classNames(cls.foodContainer, {
                                            [cls.foodIsSelected]: isSelected
                                        }, [])}
                                    >
                                        <div className={cls.foodWrap}>
                                            <div>
                                                <img className={cls.foodImage} src={item.product.image} alt=""/>
                                            </div>
                                            <div>
                                                <div className={cls.foodTitle}>
                                                    {item?.product?.title}
                                                </div>
                                                <div>
                                                    Время готовки: {item?.product?.prepare_time}
                                                </div>
                                                <div>
                                                    Цена: {item?.product?.price}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cls.second}>
                                            <div className={cls.quantity}>
                                                Количество: {item.quantity}
                                            </div>

                                            <div
                                                className={classNames(cls.checkbox, {
                                                    [cls.checkboxIsActive]: isSelected
                                                })}
                                            >
                                                <CheckIcon />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className={cls.actions}>
                            <div
                                className={cls.cancelButton}
                                onClick={() => {
                                    setCheckedId(null)
                                    setIsSelectProduct(false)
                                    setSelectedProducts([])
                                }}
                            >
                                Вернуться назад
                            </div>
                            <div
                                className={classNames(cls.applyButton, {
                                    [cls.disabledApply]: checkedId === null || selectedProducts?.length === 0
                                }, [])}
                                onClick={() => {
                                    if(checkedId === null || selectedProducts?.length === 0) return;
                                    dispatch(updateStatusToRejectedRequest({
                                        id: id,
                                        status: status
                                    }))
                                    dispatch(sendReasonOfCancelRequest({
                                        id: id,
                                        data: {
                                            reason: checkedId,
                                            products: selectedProducts
                                        }
                                    }))
                                    onClose();

                                }}
                            >
                                Подтвердить причину отказа
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <h1 className={cls.title}>Выберите причину отказа для заказа #{secretCode ? secretCode : id}:</h1>
                        <div className={cls.reasonContainer}>
                            {(list?.length === 0 && isPending) && (
                                <div className={cls.reasonListLoader}>
                                    <Loader />
                                </div>
                            )}
                            {list?.map((item: any) => (
                                <div
                                    key={item.id}
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        event.preventDefault();

                                        setCheckedId(item.id)
                                        if(item.type === "SELECT_PRODUCT") {
                                            setIsSelectProduct(true)
                                        }
                                    }}
                                    className={classNames(cls.reasonItem, {
                                        [cls.isChecked]: item.id === checkedId
                                    }, [])}
                                >
                                    <div>
                                        {item?.reason_ru}
                                    </div>
                                    {(item.id === checkedId && item.type !== "SELECT_PRODUCT") ? (
                                        <div
                                            className={classNames(cls.checkbox, {
                                                [cls.checkboxIsActive]: item.id === checkedId
                                            })}
                                        >
                                            <CheckIcon />
                                        </div>
                                    ) : null}
                                    {item.type === "SELECT_PRODUCT" ? (
                                        <div className={cls.arrowRight}></div>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                        <div
                            className={classNames(cls.applyButton, {
                                [cls.disabledApply]: checkedId === null
                            }, [])}
                            onClick={() => {
                                if(checkedId === null) return;
                                dispatch(updateStatusToRejectedRequest({
                                    id: id,
                                    status: status
                                }))
                                dispatch(sendReasonOfCancelRequest({
                                    id: id,
                                    data: {
                                        reason: checkedId,
                                        products: []
                                    }
                                }))
                                onClose();
                            }}
                        >
                            Подтвердить причину отказа
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    )
}