import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Status} from "@shared/enum/status";

interface AuthenticationState {
    email: string;
    password: string;
    accessToken: string;
    refreshToken: string;
    status: Status;
    error: string;
    errorMessage: string;
}

const initialState: AuthenticationState = {
    email: '',
    password: '',
    accessToken: '',
    refreshToken: '',
    status: Status.IDLE,
    error: '',
    errorMessage: ''
}

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        authenticationRequest: (state, action: PayloadAction<any>) => {
            state.status = Status.PENDING;
        },
        authenticationSuccess: (state, action) => {
            state.status = Status.SUCCESS;
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
        },
        authenticationFailure: (state, action) => {
            state.status = Status.FAIL

        },
        setAuthenticationStatus: (state, { payload }) => {
            state.status = payload;
        },
        resetAuthenticationStatus: (state) => {
            state.status = Status.IDLE;
        },
        logoutRequest: (state) => {
        },
        logoutSuccess: (state) => {
            state.accessToken = initialState.accessToken;
            state.refreshToken = initialState.refreshToken;
            localStorage.clear();
        },
        logoutFailure: (state) => {
            state.accessToken = initialState.accessToken;
            state.refreshToken = initialState.refreshToken;
            localStorage.clear();
        },

    }
})


export const {
    authenticationRequest,
    authenticationSuccess,
    authenticationFailure,
    resetAuthenticationStatus,
    logoutRequest,
    logoutSuccess,
    logoutFailure
} = authenticationSlice.actions;

export const { reducer: authenticationReducer } = authenticationSlice;
