import { all } from 'redux-saga/effects';

import {watchAuthenticationEffects} from "@entities/Authentication/model/effects/authenticationEffects";
import {watchBlockListEffects} from "@entities/BlockList/model/effects/blockListEffects";
import {watchOrderEffects} from "@entities/Order/model/effects/orderEffects";
import {watchSocketEffects} from "@entities/Socket/model/effects/socketEffects";
import {watchWorkDaysEffects} from "@entities/WorkDays/model/effects/workDaysEffects";

export function* rootSaga() {
    yield all([
        watchAuthenticationEffects(),
        watchOrderEffects(),
        watchBlockListEffects(),
        watchSocketEffects(),
        watchWorkDaysEffects(),
    ]);
}
