import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {WorkDaysCard} from "@widgets/WorkDaysCard/WorkDaysCard";

import {changeWorkDayRequest, getWorkDaysRequest} from "@entities/WorkDays/model/slice/workDaysSlice";
import {selectWorkDaysListData} from "@entities/WorkDays/model/selectors/workDaysSelectors";

import {Modal} from "@shared/ui/Modal/Modal";

import cls from "./WorkDaysPage.module.scss";

export const WorkDaysPage = () => {
    const dispatch = useDispatch();

    const workDays = useSelector(selectWorkDaysListData);

    const [workDayId, setWorkDayId] = useState(null);
    const [dayOff, setDayOff] = useState(false);
    const [showConfirmPage, setShowConfirmPage] = useState(false);

    useEffect(() => {
        dispatch(getWorkDaysRequest())
    }, [dispatch]);

    return (
        <div>
            <div className={cls.title}>
                Рабочий график филиала:
            </div>
            <div className={cls.wrap}>
                {workDays?.map((item: any) => (
                    <WorkDaysCard
                        key={item?.id}
                        item={item}
                        checked={!item?.day_off}
                        onClick={() => {
                            setShowConfirmPage(true)
                            setWorkDayId(item?.id)
                            setDayOff(item?.day_off)
                        }}
                    />
                ))}
            </div>
            <Modal
                isOpen={showConfirmPage}
            >
                <div className={cls.container}>
                    <div className={cls.panel}>
                        <div className={cls.panelTitle}>
                            Вы уверены что хотите изменить график филиала?
                        </div>
                        <div className={cls.buttons}>
                            <button
                                className={cls.back}
                                onClick={() => {
                                    setShowConfirmPage(false);
                                }}
                            >
                                Назад
                            </button>
                            <button
                                className={cls.submit}
                                onClick={() => {
                                    dispatch(changeWorkDayRequest({
                                        id: workDayId,
                                        dayOff: dayOff,
                                        onSuccess: () => {
                                            setShowConfirmPage(false);
                                            setWorkDayId(null);
                                            setDayOff(false)
                                        }
                                    }))

                                }}
                            >
                                Подтвердить
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}