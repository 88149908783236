import {createSlice} from "@reduxjs/toolkit";
import {Status} from "@shared/enum/status";

interface WorkDaysState {
    workDays: {
        data: any,
        status: Status,
        error: any
    },
    changeWorkDay: {
        data: any,
        status: Status,
        error: any
    }
    user: {
        data: any,
        status: Status,
        error: any,
    }
}

const initialState: WorkDaysState = {
    workDays: {
        data: [],
        status: Status.IDLE,
        error: null
    },
    changeWorkDay: {
        data: [],
        status: Status.IDLE,
        error: null
    },
    user: {
        data: null,
        status: Status.IDLE,
        error: null
    }
}

const workDaysSlice = createSlice({
    name: 'workDays',
    initialState,
    reducers: {
        getWorkDaysRequest: (state) => {
            state.workDays.status = Status.PENDING;
        },
        getWorkDaysSuccess: (state, { payload }) => {
            state.workDays.status = Status.SUCCESS;
            state.workDays.data = payload;
        },
        getWorkDaysFailure: (state) => {
            state.workDays.status = Status.FAIL;
            state.workDays.data = null;
        },
        changeWorkDayRequest: (state, action) => {
            state.changeWorkDay.status = Status.PENDING;
        },
        changeWorkDaySuccess: (state, { payload: { id, response }}) => {
            state.changeWorkDay.status = Status.SUCCESS;
            state.changeWorkDay.data = response;
            let workDays = JSON.parse(JSON.stringify(state.workDays.data))

            const index = workDays?.findIndex((item: any) => item?.id === id);
            if(typeof index === "number") {
                workDays.splice(index, 1, response);
                state.workDays.data = workDays;
            }
        },
        changeWorkDayFailure: (state) => {
            state.changeWorkDay.status = Status.FAIL;
            state.changeWorkDay.data = null;
        },

        getUserInfoRequest: (state) => {
            state.user.status = Status.PENDING;
        },
        getUserInfoSuccess: (state, action) => {
            state.user.status = Status.SUCCESS;
            state.user.data = action.payload;

        },
        getUserInfoFailure: (state) => {
            state.user.status = Status.FAIL;

        }
    }
})


export const {
    getWorkDaysRequest,
    getWorkDaysSuccess,
    getWorkDaysFailure,
    changeWorkDayRequest,
    changeWorkDaySuccess,
    changeWorkDayFailure,
    getUserInfoRequest,
    getUserInfoSuccess,
    getUserInfoFailure
} = workDaysSlice.actions;

export const { reducer: workDaysReducer } = workDaysSlice;
