


const selectOrderSlice = (state: any) => state.orderReducer


export const selectReasonList = (state: any) => selectOrderSlice(state).reasonList
export const selectReasonListData = (state: any) => selectReasonList(state).data
export const selectReasonListStatus = (state: any) => selectReasonList(state).status

export const selectNewOrderList = (state: any) => selectOrderSlice(state).newOrderList
export const selectNewOrderListData = (state: any) => selectNewOrderList(state).data
export const selectNewOrderListNext = (state: any) => selectNewOrderList(state).next
export const selectNewOrderListCurrentPage = (state: any) => selectNewOrderList(state).currentPage
export const selectNewOrderListLoadMore = (state: any) => selectNewOrderList(state).loadMore
export const selectNewOrderListStatus = (state: any) => selectNewOrderList(state).status

export const selectInProgressOrderList = (state: any) => selectOrderSlice(state).inProgressOrderList
export const selectInProgressOrderListData = (state: any) => selectInProgressOrderList(state).data
export const selectInProgressOrderListStatus = (state: any) => selectInProgressOrderList(state).status
export const selectInProgressOrderListNext = (state: any) => selectInProgressOrderList(state).next
export const selectInProgressOrderListCurrentPage = (state: any) => selectInProgressOrderList(state).currentPage
export const selectInProgressOrderListLoadMore = (state: any) => selectInProgressOrderList(state).loadMore


export const selectReadyOrderList = (state: any) => selectOrderSlice(state).readyOrderList
export const selectReadyOrderListData = (state: any) => selectReadyOrderList(state).data
export const selectReadyOrderListStatus = (state: any) => selectReadyOrderList(state).status
export const selectReadyOrderListNext = (state: any) => selectReadyOrderList(state).next
export const selectReadyOrderListCurrentPage = (state: any) => selectReadyOrderList(state).currentPage
export const selectReadyOrderListLoadMore = (state: any) => selectReadyOrderList(state).loadMore

export const selectUncollectedOrderList = (state: any) => selectOrderSlice(state).uncollectedOrderList
export const selectUncollectedOrderListData = (state: any) => selectUncollectedOrderList(state).data
export const selectUncollectedOrderListStatus = (state: any) => selectUncollectedOrderList(state).status
export const selectUncollectedOrderListNext = (state: any) => selectUncollectedOrderList(state).next
export const selectUncollectedOrderListCurrentPage = (state: any) => selectUncollectedOrderList(state).currentPage
export const selectUncollectedOrderListLoadMore = (state: any) => selectUncollectedOrderList(state).loadMore