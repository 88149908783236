import {FC, Fragment, useState} from "react";
import {useDispatch} from "react-redux";
import dayjs from "dayjs";
//@ts-ignore
import cls from "./OrderCard.module.scss";
import {
    getReasonListRequest,
    updateOrderByIdFromInProgressToReadyRequest, updateOrderByIdFromNewToInProgressRequest,
    updateOrderByIdFromReadyToDoneRequest, updateOrderByIdFromReadyToUndoneRequest
} from "@entities/Order/model/slice/orderSlice";
import {Modal} from "../Modal/Modal";
import {ReasonPage} from "@pages/ReasonPage/ReasonPage";
import {AccordionArrow} from "../../icons/AccordionArrow/AccordionArrow";
import {classNames} from "../../lib";
import {BlockUserPage} from "@pages/BlockUserPage/BlockUserPage";
import {updateIsReadNotificationById} from "@entities/NotificationList/model/slice/notificationListSlice";


export const OrderCard: FC<any> = ({id, prepareTime, price, phone, secretCode, createdAt, foodOrders, status}) => {
    const dispatch = useDispatch();
    const [isReasonWindowIsOpened, setIsReasonWindowIsOpened] = useState<boolean>(false);
    const [showBlockUserPage, setShowBlockUserPage] = useState<boolean>(false);
    const [showProducts, setShowProducts] = useState(true)

    const totalCount = foodOrders?.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.quantity, 0)


    return (
        <div className={cls.container}>

            <div className={cls.header}>
                <div className={cls.orderTitle}>
                    Заказ №{secretCode ? secretCode : id}
                </div>
                <div className={cls.orderTime}>
                    {dayjs(createdAt).format("DD-MM-YYYY HH:mm")}
                </div>
            </div>
            <div
                onClick={() => {
                    setShowProducts(prevState => !prevState)
                }}
                className={cls.accordionContainer}
            >
                <div className={cls.accordionTitle}>
                    <div className={classNames(cls.accordionSvg, {
                        [cls.accordionIsOpened]: showProducts
                    }, [])}>
                        <AccordionArrow
                            size={32}
                        />
                    </div>
                    <div className={cls.accordionTitleText}>Товары</div>
                </div>
                <div className={cls.accordionCount}>
                    Общее количество блюд: {totalCount}
                </div>
            </div>
            {showProducts ? (
                <div className={cls.foodOrder}>
                    {foodOrders?.map((item: any, index: number) => (
                        <div
                            key={index}
                            className={cls.foodContainer}
                        >
                            <div className={cls.foodWrap}>
                                <div>
                                    <img className={cls.foodImage} src={item.product.image} alt=""/>
                                </div>
                                <div>
                                    <div className={cls.foodTitle}>
                                        {item?.product?.title}
                                    </div>
                                    <div>
                                        Время готовки: {item?.product?.prepare_time}
                                    </div>
                                    <div>
                                        Цена: {item?.product?.price}
                                    </div>
                                </div>
                            </div>
                            <div className={cls.quantity}>
                                Количество: {item?.quantity}
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
            <div className={cls.additionalInfo}>
                <div className={cls.orderTimeWrap}>
                    <div>
                        Номер телефона клиента:
                    </div>
                    <div>
                        {phone ? phone : "Не указан"}

                    </div>
                </div>
                <div className={cls.orderTimeWrap}>
                    <div>
                        Общее время готовки:
                    </div>
                    <div>
                        {prepareTime ? `${Math.ceil(prepareTime)} минут` : "Не определено"}

                    </div>
                </div>
                <div className={cls.separate} />
                <div className={cls.totalPrice}>
                    <div>
                        Итоговая цена:
                    </div>
                    <div>
                        {price ? `${price} c` : "Не определена"}
                    </div>
                </div>
            </div>

            <div className={cls.actions}>
                {status === "new" && (
                    <Fragment>
                        <div
                            className={cls.cancelButton}
                            onClick={() => {
                                dispatch(getReasonListRequest())
                                setIsReasonWindowIsOpened(true);
                            }}
                        >
                            Отклонить заказ
                        </div>
                        <div
                            className={cls.applyButton}
                            onClick={() => {
                                dispatch(updateIsReadNotificationById(id))
                                dispatch(updateOrderByIdFromNewToInProgressRequest(id))
                            }}
                        >
                            Принять заказ
                        </div>
                    </Fragment>
                )}
                {status === "ready" && (
                    <Fragment>
                        <div
                            className={cls.cancelButton}
                            onClick={() => {
                                dispatch(updateOrderByIdFromReadyToUndoneRequest(id))
                            }}
                        >
                            Заказ не отдан
                        </div>
                        <div
                            className={cls.applyButton}
                            onClick={() => {
                                dispatch(updateOrderByIdFromReadyToDoneRequest(id))
                            }}
                        >
                            Заказ отдан
                        </div>
                    </Fragment>
                )}
                {status === "in_progress" && (
                    <Fragment>
                        <div
                            className={cls.cancelButton}
                            onClick={() => {
                                dispatch(getReasonListRequest())
                                setIsReasonWindowIsOpened(true);
                            }}
                        >
                            Отклонить заказ
                        </div>
                        <div
                            className={cls.applyButton}
                            onClick={() => {
                                dispatch(updateOrderByIdFromInProgressToReadyRequest(id))
                            }}
                        >
                            Заказ готов
                        </div>
                    </Fragment>
                )}
                {status === "un_accepted" && (
                    <Fragment>
                        <div
                            className={cls.cancelButton}
                            onClick={() => {
                                dispatch(getReasonListRequest())
                                setShowBlockUserPage(true);
                            }}
                        >
                            Заблокировать пользователя
                        </div>
                    </Fragment>
                )}
            </div>
            <Modal isOpen={showBlockUserPage} >
                <BlockUserPage
                    id={id}
                    onClose={(event: any) => {
                        setShowBlockUserPage(false)
                    }}
                />
            </Modal>

            <Modal isOpen={isReasonWindowIsOpened} >
                <ReasonPage
                    secretCode={secretCode}
                    id={id}
                    status={status}
                    product={foodOrders}
                    onClose={(event: any) => {
                        dispatch(updateIsReadNotificationById(id))
                        setIsReasonWindowIsOpened(false)
                    }}
                />
            </Modal>

        </div>
    )
}


