import {useDispatch, useSelector} from "react-redux";

import InfiniteScroll from "react-infinite-scroller";


import {getNewOrderListRequest} from "@entities/Order/model/slice/orderSlice";
import {
    selectNewOrderListCurrentPage,
    selectNewOrderListLoadMore,
    selectNewOrderListData,
    selectNewOrderListNext,
    selectNewOrderListStatus
} from "@entities/Order/model/selectors/orderSelectors";


import {OrderCard} from "@shared/ui/OrderCard/OrderCard";
import {Loader} from "@shared/ui/Loader/Loader";
import {classNames} from "@shared/lib";
import {Status} from "@shared/enum/status";

import cls from "./NewOrderTab.module.scss";

export const NewOrderTab = () => {
    const dispatch = useDispatch();
    const orderList = useSelector(selectNewOrderListData);
    const status = useSelector(selectNewOrderListStatus);
    const nextPage = useSelector(selectNewOrderListNext);
    const currentPage = useSelector(selectNewOrderListCurrentPage);
    const loadMore = useSelector(selectNewOrderListLoadMore);

    // const [play] = useSound(mySound)

    //
    // const addNewOrderInList = (value: any) => {
    //     const data = value.data;
    //
    //     if(data.status === "new") {
    //         dispatch(addOrderList(data))
    //         // play();
    //     }
    //     if(data.status === "rejected") {
    //         dispatch(removeItemFromOrderList(data))
    //     }
    // }
    //
    // useEffect(() => {
    //     function onConnect() {
    //         socket.on('messages', addNewOrderInList)
    //     }
    //
    //     function onDisconnect() {
    //         socket.off('messages', addNewOrderInList)
    //     }
    //
    //     const authDetails = localStorage.getItem('user') || null;
    //
    //     if(authDetails) {
    //         const authToken = JSON.parse(authDetails)
    //         const splitValue = authToken?.accessToken.split(".")[1];
    //         const currentUser = JSON.parse(atob(splitValue))
    //
    //
    //         socket.on('connect', onConnect);
    //         socket.emit(
    //             "bind:channel",
    //             {
    //                 "user_id": currentUser.id
    //             }
    //         )
    //         socket.on('disconnect', onDisconnect);
    //     }
    //
    //
    //
    //     return () => {
    //         socket.off('connect', onConnect);
    //         socket.off('disconnect', onDisconnect);
    //     };
    // }, [dispatch, addNewOrderInList]);

    return (
        <div>
            <div className={cls.wrap}>

                {(orderList?.length === 0 && status !== Status.PENDING) && (
                    <div className={cls.newOrderTabIsEmpty}>
                        На данный момент нет новых заказов
                    </div>
                )}
                {/*{(orderList?.length === 0 && status === Status.PENDING) && (*/}
                {/*    <div className={cls.newOrderTabIsEmpty}>*/}
                {/*        <div className={cls.loader} />*/}
                {/*    </div>*/}
                {/*)}*/}
                <InfiniteScroll
                    pageStart={0}
                    loadMore={() => {
                        if(currentPage !== nextPage) {
                            dispatch(getNewOrderListRequest({page: nextPage}))
                        }
                    }}
                    hasMore={loadMore}
                    loader={(
                        <div key={0} className={classNames(cls.scrollLoader, {
                            [cls.first]: orderList?.length === 0 && status === Status.PENDING
                        }, [])}>
                            <Loader />
                        </div>
                    )}
                >
                    <div className={cls.orderCards}>
                        {orderList?.length > 0 && orderList?.map((item: any) => (
                            <OrderCard
                                key={item?.id}
                                id={item?.id}
                                secretCode={item?.secret_code}
                                phone={item?.user?.phone}
                                prepareTime={item?.prepare_time}
                                price={item?.price}
                                createdAt={item?.created_at}
                                foodOrders={item?.products}
                                status="new"
                            />
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    )
}