// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Nunito/Nunito-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Nunito/Nunito-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Nunito/Nunito-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/Nunito/Nunito-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/Nunito/Nunito-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Nunito;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: 1000;
  font-style: normal;
}
@font-face {
  font-family: Nunito;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Nunito;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Nunito;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Nunito;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype");
  font-weight: 300;
  font-style: normal;
}
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Nunito;
}`, "",{"version":3,"sources":["webpack://./src/app/styles/fonts/fonts.scss","webpack://./src/app/styles/index.scss","webpack://./src/app/styles/reset.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,+DAAA;EACA,iBAAA;EACA,kBAAA;ACCJ;ADEA;EACI,mBAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;ACAJ;ADGA;EACI,mBAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;ACDJ;ADIA;EACI,mBAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;ACFJ;ADKA;EACI,mBAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;ACHJ;AC7BA;EACI,wBAAA;EACA,qBAAA;AD+BJ;;AC5BA;EACI,aAAA;EACA,mBAAA;AD+BJ;;AAnCA;EACI,sBAAA;EACA,SAAA;EACA,UAAA;EACA,mBAAA;AAsCJ","sourcesContent":["@font-face {\n    font-family: Nunito;\n    src: url(\"./Nunito/Nunito-Black.ttf\") format(\"truetype\");\n    font-weight: 1000;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: Nunito;\n    src: url(\"./Nunito/Nunito-Bold.ttf\") format(\"truetype\");\n    font-weight: 700;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: Nunito;\n    src: url(\"./Nunito/Nunito-Medium.ttf\") format(\"truetype\");\n    font-weight: 600;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: Nunito;\n    src: url(\"./Nunito/Nunito-Regular.ttf\") format(\"truetype\");\n    font-weight: 400;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: Nunito;\n    src: url(\"./Nunito/Nunito-Light.ttf\") format(\"truetype\");\n    font-weight: 300;\n    font-style: normal;\n}\n","@import 'fonts/fonts';\n@import 'reset';\n\n* {\n    box-sizing: border-box;\n    margin: 0;\n    padding: 0;\n    font-family: Nunito;\n}\n","* {\n    -ms-overflow-style: none;\n    scrollbar-width: none;\n}\n\n*::-webkit-scrollbar {\n    display: none;\n    width: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
