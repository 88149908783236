import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {Status} from "@shared/enum/status";

interface NotificationListState {
    data: Notification[]
    status: Status
    error: any

}


interface Notification {
    id: number;
    isRead: boolean;
    phone: string;
    code: string;
    productCount: number;
    price: number;
    status: string;
}

const initialState: NotificationListState = {
    data: [],
    status: Status.IDLE,
    error: null
}

const notificationListSlice = createSlice({
    name: 'notificationList',
    initialState,
    reducers: {
        addToNotificationList: (state, action: PayloadAction<Notification>) => {
            state.data.push(action.payload)
        },
        updateIsReadNotificationById: (state, action: PayloadAction<number>) => {
            for (const element of state.data) {
                if (element.id === action.payload) {
                    element.isRead = true;
                }
            }
        }
    }
})


export const {
    addToNotificationList,
    updateIsReadNotificationById
} = notificationListSlice.actions;

export const { reducer: notificationListReducer } = notificationListSlice;
