

const selectWorkDaysSlice = (state: any) => state.workDaysSlice


export const selectWorkDaysList = (state: any) => selectWorkDaysSlice(state).workDays;
export const selectWorkDaysListData = (state: any) => selectWorkDaysList(state).data;
export const selectWorkDaysListStatus = (state: any) => selectWorkDaysList(state).status;
export const selectUser = (state: any) => selectWorkDaysSlice(state).user;
export const selectUserData = (state: any) => selectUser(state).data;
