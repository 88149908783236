import {FC} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

import SidebarLayout from "@shared/layouts/SidebarLayout/SidebarLayout";
import {classNames} from "@shared/lib";

import cls from "./OrderNavbarLayout.module.scss"

const tabs = [
    {
        id: 1,
        name: "Новые",
        link: "/dashboard/order/new"
    },
    {
        id: 2,
        name: "В процессе",
        link: "/dashboard/order/in-progress"
    },
    {
        id: 3,
        name: "Готовые",
        link: "/dashboard/order/ready"
    }
]

export const OrderNavbarLayout: FC<any> = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    return (
        <SidebarLayout>
            <div className={cls.container}>
                <div className={cls.navbar}>
                    {tabs?.map((item: any, index: number) => (
                        <div
                            key={index}
                            onClick={() => {
                                navigate(item.link)
                            }}
                            className={classNames(cls.navbarItem, {
                                [cls.active]: item.link === pathname
                            }, [])}
                        >
                            {item?.name}
                        </div>
                    ))}
                    <div
                        className={classNames(cls.underline, {
                            [cls.new]: pathname === "/dashboard/order/new",
                            [cls.inProgress]: pathname === "/dashboard/order/in-progress",
                            [cls.ready]: pathname === "/dashboard/order/ready"
                        }, [])}
                    />
                </div>
            </div>
            <div>
                <Outlet />
            </div>
        </SidebarLayout>
    )
}