import {eventChannel} from "redux-saga";
import {call, put, take, takeEvery, select} from "redux-saga/effects";

import {disconnectSocket, initiateSocketConnection, subscribeToChat} from "@shared/services/Socket";

import {
    socketConnectActionFailure,
    socketConnectActionRequest,
    socketConnectActionSuccess
} from "../slice/socketSlice";
import {TConnectSocketParams} from "../types/socketTypes";
import {
    selectAuthenticationAccessToken,
} from "@entities/Authentication/model/selectors/authenticationSelectors";


export function createEventChannel({ message, accessToken }: TConnectSocketParams)  {
    return eventChannel(emitter => {
        const chatHandler = (error: any, data: any) => {
            if (error) {
                return emitter({error});
            }
            return emitter(data);
        };


        if(accessToken) {
            const splitValue: string = accessToken?.split(".")[1];
            const currentUser = JSON.parse(atob(splitValue))

            initiateSocketConnection(currentUser?.id);
            subscribeToChat(message, chatHandler);
        }





        return () => {
            disconnectSocket();
        };
    })
}

function* createSocketChannel({ payload: { message } }: any): any {
    try {
        const accessToken = yield select(selectAuthenticationAccessToken)
        const socketChannel = yield call(createEventChannel, { message, accessToken });
        while (true) {
            const payload = yield take(socketChannel);
            yield put(socketConnectActionSuccess(payload.data));
        }
    }
    catch(error) {
        yield put(socketConnectActionFailure({ error }));
    }
}


export function* watchSocketEffects() {
    yield takeEvery(socketConnectActionRequest, createSocketChannel);
}

