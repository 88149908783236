import {SagaIterator} from "redux-saga";
import {call, put, takeLatest} from "redux-saga/effects";
import {
    getInProgressOrderListFailure,
    getInProgressOrderListRequest,
    getInProgressOrderListSuccess,
    getNewOrderListFailure,
    getNewOrderListRequest,
    getNewOrderListSuccess,
    getReadyOrderListFailure,
    getReadyOrderListRequest,
    getReadyOrderListSuccess,
    getReasonListFailure,
    getReasonListRequest,
    getReasonListSuccess,
    getUncollectedOrderListFailure,
    getUncollectedOrderListRequest,
    getUncollectedOrderListSuccess,
    sendReasonOfCancelFailure,
    sendReasonOfCancelRequest,
    sendReasonOfCancelSuccess,
    updateOrderByIdFromInProgressToReadyFailure,
    updateOrderByIdFromInProgressToReadyRequest,
    updateOrderByIdFromInProgressToReadySuccess,
    updateOrderByIdFromNewToInProgressFailure,
    updateOrderByIdFromNewToInProgressRequest,
    updateOrderByIdFromNewToInProgressSuccess,
    updateOrderByIdFromReadyToDoneFailure,
    updateOrderByIdFromReadyToDoneRequest,
    updateOrderByIdFromReadyToDoneSuccess,
    updateOrderByIdFromReadyToUndoneFailure,
    updateOrderByIdFromReadyToUndoneRequest,
    updateOrderByIdFromReadyToUndoneSuccess,
    updateStatusToRejectedFailure,
    updateStatusToRejectedRequest,
    updateStatusToRejectedSuccess
} from "../slice/orderSlice";
import {Order} from "../../api/Order";
import {OrderStatus} from "../../../../shared/enum/orderStatus";
import {$api} from "../../../../shared/api/api";

function* getNewOrderListEffect ({payload}: any): SagaIterator {
    try {
        const response = yield call(Order.getOrderList, OrderStatus.NEW, payload.page);
        yield put(getNewOrderListSuccess(response));
    } catch (error) {
        yield put(getNewOrderListFailure());
    }
}


function* getInProgressOrderListEffect ({payload}: any): SagaIterator {
    try {
        const response = yield call(Order.getOrderList, OrderStatus.IN_PROGRESS, payload.page);
        yield put(getInProgressOrderListSuccess(response));
    } catch (error) {
        yield put(getInProgressOrderListFailure());
    }
}


function* getReadyOrderListEffect ({payload}: any): SagaIterator {
    try {
        const response = yield call(Order.getOrderList, OrderStatus.READY, payload.page);
        yield put(getReadyOrderListSuccess(response));
    } catch (error) {
        yield put(getReadyOrderListFailure());
    }
}

function* getUncollectedOrderListEffect ({payload}: any): SagaIterator {
    try {
        const response = yield call(Order.getOrderList, OrderStatus.UNFINISHED, payload.page);
        yield put(getUncollectedOrderListSuccess(response));
    } catch (error) {
        yield put(getUncollectedOrderListFailure());
    }
}


function* updateOrderByIdFromNewToInProgressEffect ({payload}: any): SagaIterator {
    try {
        const response = yield call(Order.updateOrderStatusById, payload, OrderStatus.IN_PROGRESS.toLowerCase());
        yield put(updateOrderByIdFromNewToInProgressSuccess(response));
    } catch (error) {
        yield put(updateOrderByIdFromNewToInProgressFailure());
    }
}

function* updateOrderByIdFromInProgressToReadyEffect ({payload}: any): SagaIterator {
    try {
        const response = yield call(Order.updateOrderStatusById, payload, OrderStatus.READY.toLowerCase());
        yield put(updateOrderByIdFromInProgressToReadySuccess(response));
    } catch (error) {
        yield put(updateOrderByIdFromInProgressToReadyFailure());
    }
}


function* updateOrderByIdFromReadyToDoneEffect ({payload}: any): SagaIterator {
    try {
        const response = yield call(Order.updateOrderStatusById, payload, OrderStatus.FINISHED.toLowerCase());
        yield put(updateOrderByIdFromReadyToDoneSuccess(response));
    } catch (error) {
        yield put(updateOrderByIdFromReadyToDoneFailure());
    }
}

function* updateOrderByIdFromReadyToUndoneEffect ({payload}: any): SagaIterator {
    try {
        const response = yield call(Order.updateOrderStatusById, payload, OrderStatus.UNFINISHED.toLowerCase());
        yield put(updateOrderByIdFromReadyToUndoneSuccess(response));
    } catch (error) {
        yield put(updateOrderByIdFromReadyToUndoneFailure());
    }
}

function* updateStatusToRejectedEffect ({payload}: any): SagaIterator {
    try {
        yield call(Order.updateOrderStatusById, payload.id, OrderStatus.REJECTED.toLowerCase());
        yield put(updateStatusToRejectedSuccess({status: payload.status, id: payload.id}));
    } catch (error) {
        yield put(updateStatusToRejectedFailure());
    }
}

function* getReasonListEffect ({payload}: any): SagaIterator {
    try {
        const response = yield call(Order.getReasonListApi);
        yield put(getReasonListSuccess(response));
    } catch (error) {
        yield put(getReasonListFailure());
    }
}

function* sendReasonOfCancelEffect ({payload}: any): SagaIterator {
    try {
        const response = yield call(Order.sendReasonOfCancelApi, payload.id, payload.data);
        yield put(sendReasonOfCancelSuccess(response));
    } catch (error) {
        yield put(sendReasonOfCancelFailure());
    }
}

export function* watchOrderEffects (): SagaIterator {
    yield takeLatest(getNewOrderListRequest, getNewOrderListEffect);
    yield takeLatest(getInProgressOrderListRequest, getInProgressOrderListEffect);
    yield takeLatest(getReadyOrderListRequest, getReadyOrderListEffect);
    yield takeLatest(getUncollectedOrderListRequest, getUncollectedOrderListEffect);
    yield takeLatest(updateOrderByIdFromNewToInProgressRequest, updateOrderByIdFromNewToInProgressEffect);
    yield takeLatest(updateOrderByIdFromInProgressToReadyRequest, updateOrderByIdFromInProgressToReadyEffect);
    yield takeLatest(updateOrderByIdFromReadyToDoneRequest, updateOrderByIdFromReadyToDoneEffect);
    yield takeLatest(updateOrderByIdFromReadyToUndoneRequest, updateOrderByIdFromReadyToUndoneEffect);
    yield takeLatest(getReasonListRequest, getReasonListEffect);
    yield takeLatest(sendReasonOfCancelRequest, sendReasonOfCancelEffect);
    yield takeLatest(updateStatusToRejectedRequest, updateStatusToRejectedEffect);
}