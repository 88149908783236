import {ReactPortal} from "../ReactPortal/ReactPortal";
import {FC} from "react";
// @ts-ignore
import cls from './Modal.module.scss';

export const Modal: FC<any> = ({ isOpen, children }) => {

    if(!isOpen) return null;

    return (
        <ReactPortal>
            <div className={cls.modalFixed}>
                { children }
            </div>
        </ReactPortal>
    )
}
