import {useDispatch, useSelector} from "react-redux";

import InfiniteScroll from "react-infinite-scroller";

import {
    selectUncollectedOrderListCurrentPage,
    selectUncollectedOrderListData,
    selectUncollectedOrderListLoadMore,
    selectUncollectedOrderListNext,
    selectUncollectedOrderListStatus
} from "@entities/Order/model/selectors/orderSelectors";
import {getUncollectedOrderListRequest} from "@entities/Order/model/slice/orderSlice";

import {classNames} from "@shared/lib";
import {Status} from "@shared/enum/status";
import {Loader} from "@shared/ui/Loader/Loader";
import {OrderCard} from "@shared/ui/OrderCard/OrderCard";

import cls from "./UncollectedOrderPage.module.scss";


export const UncollectedOrderPage = () => {
    const dispatch = useDispatch();

    const orderList = useSelector(selectUncollectedOrderListData);
    const status = useSelector(selectUncollectedOrderListStatus);
    const nextPage = useSelector(selectUncollectedOrderListNext);
    const currentPage = useSelector(selectUncollectedOrderListCurrentPage);
    const loadMore = useSelector(selectUncollectedOrderListLoadMore);

    return (
        <div>
            <h1 className={cls.title}>
                Не забранные заказы
            </h1>
            {(orderList?.length === 0 && status !== Status.PENDING) && (
                <div className={cls.newOrderTabIsEmpty}>
                    На данный момент нет новых заказов
                </div>
            )}
            <InfiniteScroll
                pageStart={0}
                loadMore={() => {
                    if(currentPage !== nextPage) {
                        dispatch(getUncollectedOrderListRequest({page: nextPage}))
                    }
                }}
                hasMore={loadMore}
                loader={(
                    <div key={0} className={classNames(cls.scrollLoader, {
                        [cls.first]: orderList?.length === 0 && status === Status.PENDING
                    }, [])}>
                        <Loader />
                    </div>
                )}
            >
                <div className={cls.orderCards}>
                    {orderList?.length > 0 && orderList?.map((item: any) => (
                        <OrderCard
                            key={item?.id}
                            id={item?.id}
                            secretCode={item?.secret_code}
                            phone={item?.user?.phone}
                            prepareTime={item?.prepare_time}
                            price={item?.price}
                            createdAt={item?.created_at}
                            foodOrders={item?.products}
                            status="un_accepted"
                        />
                    ))}
                </div>
            </InfiniteScroll>
        </div>
    )
}