import {RootState} from "@app/store";


export const selectSocketSlice = (state: RootState) => state.socketSlice;


export const selectSocketChannelData = (state: RootState) => selectSocketSlice(state).channelData
export const selectSocketData = (state: RootState) => selectSocketChannelData(state).data
export const selectSocketDataStatus = (state: RootState) => selectSocketChannelData(state).status
export const selectSocketDataId = (state: RootState) => selectSocketChannelData(state).id
