// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarLayout_count__pVvSb {
  position: relative;
  background: #EA4335;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.SidebarLayout_countValue__41gME {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/shared/layouts/SidebarLayout/SidebarLayout.module.scss"],"names":[],"mappings":"AAGA;EACI,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAFJ;;AAKA;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,eAAA;EACA,cAAA;AAFJ","sourcesContent":["\n\n\n.count {\n    position: relative;\n    background: #EA4335;\n    width: 18px;\n    height: 18px;\n    border-radius: 50%;\n}\n\n.countValue {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-size: 12px;\n    color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"count": `SidebarLayout_count__pVvSb`,
	"countValue": `SidebarLayout_countValue__41gME`
};
export default ___CSS_LOADER_EXPORT___;
