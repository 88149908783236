import axios from 'axios';

export const $api = axios.create({
    baseURL: process.env.DJANGO_API || "https://test-api.gonget.net",
});


export const configureInterceptors = (store: any) => {
    const instances = [$api];

    instances.forEach((axios) => {
        axios.interceptors.request.use((config) => {
            try {
                // @ts-ignore
                const accessToken = store.getState()?.authenticationReducer?.accessToken;

                if (Boolean(accessToken)) {
                    config.headers['Authorization'] = `${accessToken}`;
                }
                config.headers['Access-Control-Allow-Origin'] = '*';
                config.headers["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE, OPTIONS, HEAD";
                config.headers["Access-Control-Allow-Headers"] = "Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Authorization, Content-Type";


            } catch (error) {
                console.log(error);
            }

            return config;
        })
    })
    instances.forEach((axios) => {
        axios.interceptors.response.use(
            response => response,
            error => {

                // if (error.response.status === 404) {
                //     window.location.href = '/404'
                // }
                // const originalRequest = error.config;
                // if (
                //     error.response.status === 401 &&
                //     !originalRequest._retry &&
                //     originalRequest.url !== "/api/v1/user-jwt-refresh"
                // ) {
                //     if (isRefreshing) {
                //         return new Promise((resolve, reject) => {
                //             failedQueue.push({ resolve, reject });
                //         })
                //             .then(token => {
                //                 originalRequest.headers.Authorization = token;
                //                 return axios(originalRequest);
                //             })
                //             .catch(err => {
                //                 return Promise.reject(err);
                //             });
                //     }
                //
                //     originalRequest._retry = true;
                //     isRefreshing = true;
                //
                //     return new Promise((resolve, reject) => {
                //         axios
                //             .post("/api/v1/user-jwt-refresh", { refresh_token: store.getState().auth?.refresh })
                //             .then(({ data }: AxiosResponse<{ access_token: string; refresh: string }>) => {
                //
                //                 axios.defaults.headers.common.Authorization = data.access_token;
                //                 originalRequest.headers.Authorization = data.access_token;
                //                 processQueue(null, data.access_token);
                //                 resolve(axios(originalRequest));
                //             })
                //             .catch(err => {
                //                 processQueue(err, '');
                //
                //                 reject(err);
                //             })
                //             .then(() => {
                //                 isRefreshing = false;
                //             });
                //     });
                // }
                //
                // return Promise.reject(error);
            },
        );
    })
}
