import { combineReducers } from "@reduxjs/toolkit";

import {authenticationReducer} from "@entities/Authentication/model/slice/authenticationSlice";
import {blockListReducer} from "@entities/BlockList/model/slice/blockListSlice";
import {orderReducer} from "@entities/Order/model/slice/orderSlice";
import {socketReducer} from "@entities/Socket/model/slice/socketSlice";
import {workDaysReducer} from "@entities/WorkDays/model/slice/workDaysSlice";
import {notificationListReducer} from "@entities/NotificationList/model/slice/notificationListSlice";

const rootReducer = combineReducers({
    authenticationReducer: authenticationReducer,
    orderReducer: orderReducer,
    blockList: blockListReducer,
    socketSlice: socketReducer,
    workDaysSlice: workDaysReducer,
    notificationListReducer: notificationListReducer,
});


export default rootReducer;
