import {$api} from "../../../shared/api/api";
import {URL_AUTHENTICATION, URL_LOGOUT} from "../consts/routes";

export class Authentication {
    static async authenticationApi(payload: any): Promise<any> {
        const response = await $api.post(URL_AUTHENTICATION, {
            login: payload.login,
            password: payload.password
        });
        return Authentication.mapAuthenticationSuccessModel(response?.data);
    }

    static async logoutApi(): Promise<any> {
        const response = await $api.post(URL_LOGOUT);
        return Authentication.mapAuthenticationSuccessModel(response.data);
    }


    static mapAuthenticationSuccessModel (data: any) {
        return {
            accessToken:  data?.jwt_access,
            refreshToken: data?.jwt_refresh,
        }
    }



}
