// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toggle_container__koq7k {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
}
.Toggle_container__koq7k .Toggle_span__WHiqb {
  display: flex;
  align-items: center;
  width: 40px;
  height: 22px;
  border-radius: 30px;
  background-color: #ddd;
  transition: 0.3s;
  padding: 0 3px;
  position: relative;
}
.Toggle_container__koq7k .Toggle_span__WHiqb::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  left: 2px;
  transition: 0.6s;
}
.Toggle_container__koq7k .Toggle_checkbox__zySds {
  display: none;
}
.Toggle_container__koq7k .Toggle_checkbox__zySds:checked ~ .Toggle_span__WHiqb {
  background-color: #47A7FF;
}
.Toggle_container__koq7k .Toggle_checkbox__zySds:checked ~ .Toggle_span__WHiqb::before {
  left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Toggle/Toggle.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AADJ;AAGI;EACI,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AADR;AAGQ;EACI,WAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,kBAAA;EACA,SAAA;EACA,gBAAA;AADZ;AAMI;EACI,aAAA;AAJR;AAQI;EACI,yBAAA;AANR;AASI;EACI,UAAA;AAPR","sourcesContent":["\n\n.container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    row-gap: 20px;\n\n    & .span {\n        display: flex;\n        align-items: center;\n        width: 40px;\n        height: 22px;\n        border-radius: 30px;\n        background-color: #ddd;\n        transition: 0.3s;\n        padding: 0 3px;\n        position: relative;\n\n        &::before {\n            content: '';\n            display: inline-block;\n            width: 18px;\n            height: 18px;\n            border-radius: 50%;\n            background-color: #fff;\n            position: absolute;\n            left: 2px;\n            transition: 0.6s;\n        }\n\n    }\n\n    & .checkbox {\n        display: none;\n    }\n\n\n    & .checkbox:checked ~ .span {\n        background-color: #47A7FF;\n    }\n\n    & .checkbox:checked ~ .span::before {\n        left: 20px;\n    }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Toggle_container__koq7k`,
	"span": `Toggle_span__WHiqb`,
	"checkbox": `Toggle_checkbox__zySds`
};
export default ___CSS_LOADER_EXPORT___;
