// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loader__l3XDQ {
  display: inline-flex;
  gap: 5px;
}

.Loader_loader__l3XDQ:before,
.Loader_loader__l3XDQ:after {
  content: "";
  width: 25px;
  aspect-ratio: 1;
  box-shadow: 0 0 0 3px inset #3a3a3a;
  animation: Loader_l4__baTIt 1.5s infinite;
}

.Loader_loader__l3XDQ:after {
  --s: -1;
  animation-delay: 0.75s;
}

@keyframes Loader_l4__baTIt {
  0% {
    transform: scaleX(var(--s, 1)) translate(0) rotate(0);
  }
  16.67% {
    transform: scaleX(var(--s, 1)) translate(-50%) rotate(0);
  }
  33.33% {
    transform: scaleX(var(--s, 1)) translate(-50%) rotate(90deg);
  }
  50%, 100% {
    transform: scaleX(var(--s, 1)) translate(0) rotate(90deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Loader/Loader.module.scss"],"names":[],"mappings":"AAEA;EACI,oBAAA;EACA,QAAA;AADJ;;AAGA;;EAEI,WAAA;EACA,WAAA;EACA,eAAA;EACA,mCAAA;EACA,yCAAA;AAAJ;;AAEA;EACI,OAAA;EACA,sBAAA;AACJ;;AACA;EACI;IAAQ,qDAAA;EAGV;EAFE;IAAQ,wDAAA;EAKV;EAJE;IAAQ,4DAAA;EAOV;EANE;IACQ,yDAAA;EAQV;AACF","sourcesContent":["\n\n.loader {\n    display: inline-flex;\n    gap: 5px;\n}\n.loader:before,\n.loader:after {\n    content: \"\";\n    width: 25px;\n    aspect-ratio: 1;\n    box-shadow: 0 0 0 3px inset #3a3a3a;\n    animation: l4 1.5s infinite;\n}\n.loader:after {\n    --s: -1;\n    animation-delay: 0.75s\n}\n@keyframes l4 {\n    0%     {transform:scaleX(var(--s,1)) translate(0) rotate(0)}\n    16.67% {transform:scaleX(var(--s,1)) translate(-50%) rotate(0)}\n    33.33% {transform:scaleX(var(--s,1)) translate(-50%) rotate(90deg)}\n    50%,\n    100%   {transform:scaleX(var(--s,1)) translate(0) rotate(90deg)}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loader_loader__l3XDQ`,
	"l4": `Loader_l4__baTIt`
};
export default ___CSS_LOADER_EXPORT___;
