import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import { rootSaga } from "./rootSaga";
import {configureInterceptors} from "@shared/api/api";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';


let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];


const appReducer = (state: any, action: any) => {
    if (action.type === 'RESET') {
        localStorage.removeItem("persist:root")
        return rootReducer(undefined, action)
    }

    return rootReducer(state, action)
}

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authenticationReducer']
}

const persistedReducer = persistReducer(persistConfig, appReducer)



export const store = configureStore({
    reducer: persistedReducer,
    middleware: middleware,
});

sagaMiddleware.run(rootSaga);
configureInterceptors(store);

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
