import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import { IconType } from 'react-icons'

import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Text,
    Drawer,
    DrawerContent,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuItem,
    MenuList, MenuDivider,
} from '@chakra-ui/react'
import {
    FiHome,
    FiMenu,
    FiChevronDown,
} from 'react-icons/fi'
import {RiListOrdered} from 'react-icons/ri'
import {APP_PAGES} from "@shared/consts/appPages";
import {getUserInfoRequest} from "@entities/WorkDays/model/slice/workDaysSlice";
import {selectUserData} from "@entities/WorkDays/model/selectors/workDaysSelectors";
import {
    selectNotificationLength,
} from "@entities/NotificationList/model/selectors/notificationListSelectors";
import cls from "./SidebarLayout.module.scss";

interface LinkItemProps {
    id: number
    name: string
    icon: IconType
    href: string
}

interface NavItemProps extends FlexProps {
    icon: IconType
    children: React.ReactNode
}

interface MobileProps extends FlexProps {
    onOpen: () => void
}

interface SidebarProps extends BoxProps {
    onClose: () => void
}

const LinkItems: Array<LinkItemProps> = [
    { id: 1, name: 'Главная', icon: FiHome, href: "/dashboard" },
    { id: 2, name: 'Заказы', icon: RiListOrdered, href: "/dashboard/order/new"  },
    // { name: 'Menu', icon: FiSettings, href: "/dashboard/menu" },
]

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    const items = useSelector(selectNotificationLength);

    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Text fontSize="2xl" fontFamily="Nunito" fontWeight="bold">
                    GoNGet
                </Text>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {LinkItems.map((link) => (
                <Link
                    key={link.name}
                    to={link.href}
                >
                    <NavItem icon={link.icon}>
                        <div
                            style={{
                                width: '100%',
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            <div>
                                {link.name}
                            </div>
                            {link?.name === "Заказы" ? (
                                <div>
                                    {items ? (
                                        <div className={cls.count}>
                                            <span className={cls.countValue}>
                                                {items}
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </NavItem>
                </Link>
            ))}
        </Box>
    )
}

const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
    return (
        <Box
            as="a"
            href="#"
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'cyan.400',
                    color: 'white',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Box>
    )
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const user = useSelector(selectUserData);


    useEffect(() => {
        dispatch(getUserInfoRequest())
    }, []);

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Text
                display={{ base: 'flex', md: 'none' }}
                fontSize="2xl"
                fontFamily="monospace"
                fontWeight="bold">
                GoNGet
            </Text>

            <HStack spacing={{ base: '0', md: '6' }}>
                {/*<Menu>*/}
                {/*    <MenuButton>*/}
                {/*        <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} />*/}
                {/*    </MenuButton>*/}
                {/*    <MenuList>*/}
                {/*        <MenuItem>*/}
                {/*            <HStack>*/}

                {/*            </HStack>*/}
                {/*        </MenuItem>*/}
                {/*    </MenuList>*/}
                {/*</Menu>*/}
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar
                                    size={'sm'}
                                    src=""
                                />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm">
                                        {user?.branch?.address}
                                    </Text>
                                    <Text fontSize="xs" color="gray.600">
                                        Кассир
                                    </Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}>
                            <MenuItem>Профиль (скоро)</MenuItem>
                            <MenuDivider />
                            <MenuItem
                                onClick={() => {
                                    navigate(APP_PAGES.NOTIFICATIONS);
                                }}
                            >
                                Уведомления
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    navigate(APP_PAGES.UNCOLLECTED_ORDER);
                                }}
                            >
                                Не забранные заказы
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    navigate(APP_PAGES.WORK_DAYS);
                                }}
                            >
                                График работы
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                                onClick={() => {
                                    dispatch({ type: "RESET" })
                                }}
                            >
                                Выйти из аккаунта
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    )
}

const SidebarWithHeader = ({ children }: any) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Box minH="100vh" bg={useColorModeValue('white.100', 'gray.900')}>
            <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            <MobileNav onOpen={onOpen} />
            <Box ml={{ base: 0, md: 60 }} p="4">
                {children}
            </Box>
        </Box>
    )
}

export default SidebarWithHeader