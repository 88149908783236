import cls from "./NotificationPage.module.scss"

export const NotificationPage = () => {
    return (
        <div>
            <div className={cls.title}>
                Уведомления
            </div>
        </div>
    )
}