import {SagaIterator} from "redux-saga";
import {call, put, takeLatest} from "redux-saga/effects";

import {
    blockListByOrderIdFailure,
    blockListByOrderIdRequest,
    blockListByOrderIdSuccess
} from "@entities/BlockList/model/slice/blockListSlice";
import {BlockList} from "@entities/BlockList/api/BlockList";
import {PayloadAction} from "@reduxjs/toolkit";


function* blockListByOrderIdEffect ({payload: { id, onSuccess }}: PayloadAction<any>): SagaIterator {
    try {
        const response = yield call(BlockList.blockListByOrderIdApi, id);
        yield put(blockListByOrderIdSuccess(response));
        onSuccess(id);
    } catch (error) {
        yield put(blockListByOrderIdFailure());
    }
}

export function* watchBlockListEffects (): SagaIterator {
    yield takeLatest(blockListByOrderIdRequest, blockListByOrderIdEffect);
}