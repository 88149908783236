// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewOrderTab_title__sN8Gf {
  font-size: 38px;
  margin-bottom: 20px;
}

.NewOrderTab_wrap__ksEaP {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}

.NewOrderTab_newOrderTabIsEmpty__jXno3 {
  color: #3a3a3a;
  font-size: 20px;
  height: 100%;
  min-height: calc(100vh - 300px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.NewOrderTab_loader__IO61h {
  display: inline-flex;
  gap: 5px;
}

.NewOrderTab_loader__IO61h:before,
.NewOrderTab_loader__IO61h:after {
  content: "";
  width: 25px;
  aspect-ratio: 1;
  box-shadow: 0 0 0 3px inset #3a3a3a;
  animation: NewOrderTab_l4__D3PFM 1.5s infinite;
}

.NewOrderTab_loader__IO61h:after {
  --s: -1;
  animation-delay: 0.75s;
}

@keyframes NewOrderTab_l4__D3PFM {
  0% {
    transform: scaleX(var(--s, 1)) translate(0) rotate(0);
  }
  16.67% {
    transform: scaleX(var(--s, 1)) translate(-50%) rotate(0);
  }
  33.33% {
    transform: scaleX(var(--s, 1)) translate(-50%) rotate(90deg);
  }
  50%, 100% {
    transform: scaleX(var(--s, 1)) translate(0) rotate(90deg);
  }
}
.NewOrderTab_orderCards__eFNmu {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.NewOrderTab_scrollLoader__Ky2Hy {
  width: 100%;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NewOrderTab_first__DYFSb {
  min-height: calc(100vh - 400px);
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/widgets/OrderTabs/ui/NewOrderTab/NewOrderTab.module.scss"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AAAF;;AAKA;EACE,cAAA;EACA,eAAA;EACA,YAAA;EACA,+BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFF;;AAMA;EACE,oBAAA;EACA,QAAA;AAHF;;AAKA;;EAEE,WAAA;EACA,WAAA;EACA,eAAA;EACA,mCAAA;EACA,8CAAA;AAFF;;AAIA;EACE,OAAA;EACA,sBAAA;AADF;;AAGA;EACE;IAAQ,qDAAA;EACR;EAAA;IAAQ,wDAAA;EAGR;EAFA;IAAQ,4DAAA;EAKR;EAJA;IACQ,yDAAA;EAMR;AACF;AAHA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAKF;;AADA;EACE,WAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAIF;;AADA;EACE,+BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAIF","sourcesContent":["\n.title {\n  font-size: 38px;\n  margin-bottom: 20px;\n}\n\n.wrap {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-top: 24px;\n}\n\n\n\n.newOrderTabIsEmpty {\n  color: #3a3a3a;\n  font-size: 20px;\n  height: 100%;\n  min-height: calc(100vh - 300px);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n\n.loader {\n  display: inline-flex;\n  gap: 5px;\n}\n.loader:before,\n.loader:after {\n  content: \"\";\n  width: 25px;\n  aspect-ratio: 1;\n  box-shadow: 0 0 0 3px inset #3a3a3a;\n  animation: l4 1.5s infinite;\n}\n.loader:after {\n  --s: -1;\n  animation-delay: 0.75s\n}\n@keyframes l4 {\n  0%     {transform:scaleX(var(--s,1)) translate(0) rotate(0)}\n  16.67% {transform:scaleX(var(--s,1)) translate(-50%) rotate(0)}\n  33.33% {transform:scaleX(var(--s,1)) translate(-50%) rotate(90deg)}\n  50%,\n  100%   {transform:scaleX(var(--s,1)) translate(0) rotate(90deg)}\n}\n\n\n.orderCards {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n\n.scrollLoader {\n  width: 100%;\n  padding: 50px 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.first {\n  min-height: calc(100vh - 400px);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `NewOrderTab_title__sN8Gf`,
	"wrap": `NewOrderTab_wrap__ksEaP`,
	"newOrderTabIsEmpty": `NewOrderTab_newOrderTabIsEmpty__jXno3`,
	"loader": `NewOrderTab_loader__IO61h`,
	"l4": `NewOrderTab_l4__D3PFM`,
	"orderCards": `NewOrderTab_orderCards__eFNmu`,
	"scrollLoader": `NewOrderTab_scrollLoader__Ky2Hy`,
	"first": `NewOrderTab_first__DYFSb`
};
export default ___CSS_LOADER_EXPORT___;
