import {createSlice} from "@reduxjs/toolkit";
import {TSocketInitialState} from "../types/socketTypes";

const initialState: TSocketInitialState = {
    isSocketConnectionLoading: false,
    isSocketConnected: false,
    error: null,
    channelData: {
        id: null,
        status: null,
        data: null
    }
}

const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        socketConnectActionRequest: (state, { payload }) => {
            state.isSocketConnectionLoading = true;
        },
        socketConnectActionSuccess: (state, { payload }) => {
            state.isSocketConnectionLoading = false;
            state.isSocketConnected = true;
            state.channelData.id = payload?.id;
            state.channelData.status = payload?.status;
            state.channelData.data = payload;
        },
        socketConnectActionFailure: (state, { payload }) => {
            state.isSocketConnectionLoading = false;
            state.isSocketConnected = false;

        },
    }
})

export const {
    socketConnectActionRequest,
    socketConnectActionSuccess,
    socketConnectActionFailure,
} = socketSlice.actions;

export const {
    reducer: socketReducer
} =  socketSlice;