import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Heading,
    useColorModeValue,
} from '@chakra-ui/react'
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {authenticationRequest} from "@entities/Authentication/model/slice/authenticationSlice";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";

const authenticationSchema = yup.object().shape({
    login: yup.string().required('This field is required.'),
    password: yup.string().required('This field is required.').min(2, 'Password must be at least 8 characters.').max(40, 'Password must be at most 40 characters.')
})

const defaultValues = {
    login: '',
    password: ''
};


export default function SimpleCard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(authenticationSchema),
        shouldFocusError: false,
        defaultValues
    })

    const onSubmitHandler = useCallback((data: any) => {
        dispatch(authenticationRequest({
            login: data.login,
            password: data.password,
            onSuccess: onSuccessHandler,
            onError: onErrorHandler
        }))
    }, [])

    const onSuccessHandler = () => {
            navigate("/dashboard");
    }

    const onErrorHandler = () => {
        console.log("error")
    }

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading fontSize={'4xl'}>Sign in to your account</Heading>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}
                >
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <Stack spacing={4}>
                            <FormControl id="email">
                                <FormLabel>Username</FormLabel>
                                <Input
                                    type="text"
                                    {...register("login")}
                                />
                            </FormControl>
                            <FormControl id="password">
                                <FormLabel>Password</FormLabel>
                                <Input
                                    type="password"
                                    {...register("password")}
                                />
                            </FormControl>
                            <Stack spacing={10}>
                                <Button
                                    type="submit"
                                    bg={'blue.400'}
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}>
                                    Sign in
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Stack>
        </Flex>
    )
}




export const SignInPage = () => {
    return (
        <div>
            <SimpleCard />
        </div>
    )
}