import {$api} from "@api/api";

import {buildUrlBlockListByOrderId} from "@entities/BlockList/consts/routes";


export class BlockList {
    static async blockListByOrderIdApi(id: number): Promise<any> {
        const response = await $api.post(buildUrlBlockListByOrderId(id));
        return response.data;
    }

}