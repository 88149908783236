import { Status } from '../enum/status';

export const useCheckRequestStatus = (status: Status) => {
    const isIdle = status === Status.IDLE;
    const isPending = status === Status.PENDING;
    const isSuccess = status === Status.SUCCESS;
    const isFail = status === Status.FAIL

    return {
        isIdle,
        isPending,
        isSuccess,
        isFail
    }
};
