import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {Toggle} from "@shared/ui/Toggle/Toggle";

import cls from "@pages/WorkDaysPage/WorkDaysPage.module.scss";

export const WorkDaysCard: FC<any> = ({
                                          checked,
                                          item,
                                          onClick
                             }) => {
    const { t } = useTranslation();

    const [isChecked, setIsChecked] = useState(false)

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    return (
        <div
            key={item?.id}
            className={cls.card}
        >
            <div>
                <div className={cls.weekday}>
                    {t(item?.weekday?.toString())}
                </div>

                <div>{item?.start_time} - {item?.end_time}</div>
            </div>

            <div className={cls.toggleWrap}>
                <div>
                    Заведение работает:
                </div>
                <div>
                    <Toggle
                        isChecked={isChecked}
                        checked={!item?.day_off}
                        onClick={onClick}
                    />
                </div>
            </div>
        </div>
    )
}