import {FC, useEffect, useState} from "react";
import cls from "./BlockUserPage.module.scss"
import {classNames} from "@shared/lib";
import {useDispatch} from "react-redux";
import {blockListByOrderIdRequest} from "@entities/BlockList/model/slice/blockListSlice";
import {removeItemFromBlockList} from "@entities/Order/model/slice/orderSlice";

export const BlockUserPage: FC<any> = ({ id, onClose }) => {
    const dispatch = useDispatch();

    const [disabledButton, setDisabledButton] = useState(true);

    const [currentTime, setCurrentTime] = useState(Date.now());
    const [expireIn] = useState(Date.now() + 5000);

    const timeBetween = expireIn - currentTime;


    const seconds = Math.floor((timeBetween / 1000) % 60);

    useEffect(() => {

        const interval = setInterval(() => {
            setCurrentTime(Date.now());
        }, 900);

        setTimeout(() => {
            setDisabledButton(false);
        }, 5000)

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={cls.shutter}>
            <div className={cls.modalContainer}>
                <h1 className={cls.title}>Вы уверены что хотите добавить пользователя в черный список?</h1>
                <div className={cls.buttons}>
                    <button
                        className={classNames(cls.button, {}, [cls.block])}
                        onClick={() => {
                            dispatch(blockListByOrderIdRequest({
                                id: id,
                                onSuccess: (id: number) => {
                                    dispatch(removeItemFromBlockList({ id: id }))
                                }
                            }))
                        }}
                        disabled={disabledButton}
                    >
                        {(disabledButton && seconds >= 0) && (
                            <div>
                                00:0{seconds}
                            </div>
                        )}
                        Заблокировать
                    </button>
                    <button
                        className={classNames(cls.button, {}, [cls.goBack])}
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Вернуть назад
                    </button>
                </div>
            </div>
        </div>
    )
}