import {$api} from "@api/api";




export class WorkDays {
    static async getWorkDaysList(): Promise<any> {
        const response = await $api.get(`/api/v1/mvp/work-days`);
        return response.data;
    }

    static async getUserInfoApi(): Promise<any> {
        const response = await $api.get(`/api/v1/casier/info/`);
        return response.data;
    }

    static async changeWorkDayApi(id: any, dayOff: any): Promise<any> {
        const response = await $api.put(`/api/v1/mvp/work-days/${id}/`, {
            "day_off": !dayOff
        });
        return response.data;
    }
}